/* eslint-disable @typescript-eslint/no-unused-vars */
import { IPagingProps } from '../models';
import { http } from './http';
import { stringify } from 'query-string';
export interface IV1CvAppliedFilters {}
export interface IV1CvApplied extends IPagingProps<IV1CvAppliedFilters> {}

export const CvAppliedCandidatesApi = {
	v1GetListWithAppiledCandidates: async (query: any) => {
		const res = await http.get(
			`/v1/api/job/list-with-applied-candidates?${stringify({
				...query,
				...query.filters,
			})}`
		);
		return res;
	},
	v1JobCommentById: async (limit?: number, page?: number, jobId?: string) => {
		const res = await http.get(`/v1/api/job-comment/admin-site/job/${jobId}`, {
			params: {
				limit,
				page,
			},
		});
		return res;
	},
	JobCommentReplyById: async (uuid?: string) => {
		const res = await http.get(`/v1/api/job-comment/admin-site/reply/${uuid}`);
		return res;
	},
	v1JobById: async (cvId: string) => {
		const res = await http.get(`/v1/api/job/sale-system/${cvId}`);
		return res.data;
	},
	v1GetProfileApplyById: async (cvId: string) => {
		const res = await http.get(`/v1/api/profile-apply/sale-system/${cvId}`);
		return res.data;
	},
	v1QuestionsById: async (jobUuid: string) => {
		const res = await http.get(`/v1/api/job-exams/jobs/${jobUuid}/exam`);
		return res.data;
	},
	v1AnswerById: async (jobUuid: string) => {
		const res = await http.get(`/v1/api/submitted-profiles/exam-results?filter.job.uuid=$eq:${jobUuid}`);
		return res.data;
	},
	v1TagById: async (jobUuid: string, tag: string) => {
		const res = await http.patch(`/v1/api/job/${jobUuid}/tag`, tag);
		return res.data;
	},
	v1GetCoverLetterById: async (jobUuid: string, userUuid: string) => {
		const res = await http.get(`/v1/api/cover-letter/${jobUuid}/${userUuid}`);
		return res.data;
	},
	v1UpdateCvPDFApply: async (submittedProfileUuid: string, formData: any) => {
		const res = await http.put(`/v1/api/submitted-profiles/${submittedProfileUuid}/redact-pdf`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
		return res.data;
	},
	v1CloneJob: async (jobUuid: string) => {
		const res = await http.get(`/v1/api/job/guarantee/${jobUuid}`);
		return res.data;
	},
};

import { EditOutlined, PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Descriptions, Modal, PageHeader } from 'antd';
import { truncate } from 'lodash';
import _get from 'lodash/get';
import moment from 'moment';
import * as React from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { saleSystemGroupApi } from '../../../core/apis';
import { groupEmployerApi } from '../../../core/apis/groupEmployer';
import { StatusTag } from '../../../core/components/tag';
import { useModal } from '../../../core/contexts';
import { Group } from '../../../core/models';
import {
	EmployerId,
	colorsEmployerScale,
	colorsEmployerStatus,
	colorsEmployerType,
} from '../../../core/models/employer';
import { saleRouter } from '../../../core/routes';
import ClaimEmployerGroupModal from './components/ClaimEmployerGroup';
import { CreatePointRequestDrawer } from './components/CreatePointRequestDrawer';
import { UpdateEmployerDrawer } from './components/UpdateEmployerDrawer';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { InputRichEditor } from '../../../core/components/form/InputRichEditor';
import { IV1UpdateEmployerGroup } from '../../../core/apis/dataSale';

interface SaleViewEmployerByIdProps {}

function NoteModal({ refetchData }: { refetchData: () => void }) {
	const noteModalMethods = useModal<any>('note');
	const schema = yup.object().shape({});
	const formMethods = useForm<any>({
		defaultValues: {
			note: '',
		},
		resolver: yupResolver(schema),
	});
	const addNoteMutation = useMutation(
		(data: any) => groupEmployerApi.v1NoteById(noteModalMethods.modalValue?.id, data),
		{
			onSuccess: () => {
				toast.success('success');
				refetchData();
				noteModalMethods.handleCloseModal();
			},
			onError: (error: any) => {
				toast.error(error?.response?.data?.message || 'error');
			},
		}
	);
	const editNoteMutation = useMutation(
		(data: any) =>
			groupEmployerApi.v1EditById(noteModalMethods.modalValue?.id, noteModalMethods.modalValue?.noteId, data),
		{
			onSuccess: () => {
				toast.success('success');
				refetchData();
				noteModalMethods.handleCloseModal();
			},
			onError: (error: any) => {
				toast.error(error?.response?.data?.message || 'error');
			},
		}
	);
	const employerGroupNoteByIdQuery = useQuery<any>(
		['employerGroupNoteById', noteModalMethods.modalValue?.id, noteModalMethods.modalValue?.noteId],
		async () => {
			const res = await groupEmployerApi.v1NoteInGroupById(
				noteModalMethods.modalValue?.id,
				noteModalMethods.modalValue?.noteId
			);
			formMethods.setValue('note', res.data.data.note);
			return res.data.data;
		},
		{
			enabled: noteModalMethods.modalValue?.type === 'edit',
			refetchOnWindowFocus: false,
		}
	);
	const _handleSubmit = (data: any) => {
		if (noteModalMethods.modalValue?.type === 'edit') {
			editNoteMutation.mutate(data);
		} else {
			addNoteMutation.mutate(data);
		}
	};
	return (
		<>
			<Modal
				title={noteModalMethods.modalValue?.type === 'edit' ? 'Edit Note' : 'Add Note'}
				open={noteModalMethods.getIsOpen()}
				onOk={formMethods.handleSubmit(_handleSubmit)}
				centered
				onCancel={() => {
					noteModalMethods.handleCloseModal();
				}}
				width={800}
			>
				<FormProvider {...formMethods}>
					<form className='w-full'>
						{noteModalMethods.modalValue?.type === 'edit' ? (
							employerGroupNoteByIdQuery.data && <InputRichEditor name='note' label='Note' placeholder='note..' />
						) : (
							<InputRichEditor name='note' label='Note' placeholder='note..' />
						)}
					</form>
				</FormProvider>
			</Modal>
		</>
	);
}

function UpdateCallNoteModal({ refetchData }: { refetchData: () => void }) {
	const updateCallNoteMethod = useModal<any>('callNote');
	const formMethods = useForm<any>({
		defaultValues: {
			groupUuid: undefined,
			saleUuid: undefined,
			scale: undefined,
			type: undefined,
			other_note: undefined,
			status: undefined,
			callNote: '',
		},
	});
	const employerGroupServiceIdQuery = useQuery<any>(
		['employer-group-service-id', updateCallNoteMethod.modalValue],
		async () => {
			const res = await groupEmployerApi.v1ById(updateCallNoteMethod.modalValue);
			return res.data.data;
		},
		{
			enabled: updateCallNoteMethod.modalValue != undefined,
			onSuccess: (data) => {
				const { callNote } = data;
				formMethods.setValue('callNote', callNote);
			},
		}
	);
	const updateCallNoteMutation = useMutation(
		async (data: IV1UpdateEmployerGroup) => {
			const res = await groupEmployerApi.v1UpdateById(updateCallNoteMethod.modalValue, data);

			return res;
		},
		{
			onSuccess: (data: any) => {
				refetchData();
				toast.success(data.message);
				updateCallNoteMethod.handleCloseModal();
			},
			onError: (res: any) => {
				toast.error(res?.data?.message);
			},
		}
	);
	const _handleSubmit = (data: any) => {
		updateCallNoteMutation.mutate(data);
	};
	return (
		<>
			{employerGroupServiceIdQuery.data && (
				<Modal
					title={'Edit Call Note'}
					open={updateCallNoteMethod.getIsOpen()}
					onOk={formMethods.handleSubmit(_handleSubmit)}
					centered
					onCancel={() => {
						updateCallNoteMethod.handleCloseModal();
					}}
					width={800}
				>
					<FormProvider {...formMethods}>
						<div className='w-full'>
							<InputRichEditor name='callNote' label='Call Note' placeholder='note..' className='' />
						</div>
					</FormProvider>
				</Modal>
			)}
		</>
	);
}
export const SaleViewEmployerById: React.FC<SaleViewEmployerByIdProps> = () => {
	const navigate = useNavigate();
	const { saleEmployerGroupUuid = '' } = useParams<{ saleEmployerGroupUuid: string }>();
	const updateModalMethods = useModal('update');
	const noteModalMethods = useModal('note');
	const updateCallNoteMethod = useModal('callNote');
	const createPointModalMethods = useModal('createPoint');
	const userId = localStorage.getItem('userId') || '';
	const claimEmployerGroupModal = useModal<any>('claimEmployerGroup');
	const [showLeadCallNote, setShowLeadCallNote] = React.useState(false);
	const [showEmpNote, setShowEmpNote] = React.useState(false);

	const employerQuery = useQuery<EmployerId>(
		['employerById', saleEmployerGroupUuid, updateModalMethods.getIsOpen()],
		async () => {
			const res = await groupEmployerApi.v1ById(saleEmployerGroupUuid);

			return res.data.data;
		},
		{
			refetchOnWindowFocus: false,
		}
	);
	const groupDetailQuery = useQuery<Group>(
		['group-detail', employerQuery.data?.group?.uuid],
		async () => {
			const { data } = await saleSystemGroupApi.v1GroupById(employerQuery.data?.group?.uuid || '');
			return data;
		},
		{
			enabled: Boolean(employerQuery.data?.group),
			refetchOnWindowFocus: false,
		}
	);
	React.useEffect(() => {
		document.title = 'Sale - Employer Management';
	}, []);


	const handleUpdateCallNote = (e) => {
		e.preventDefault();
		updateCallNoteMethod.handleOpenModal(saleEmployerGroupUuid)
	}
	return (
		<>
			<CreatePointRequestDrawer />
			<UpdateEmployerDrawer refreshData={() => employerQuery.refetch()} />
			{noteModalMethods.getIsOpen() && <NoteModal refetchData={() => employerQuery.refetch()} />}
			{updateCallNoteMethod.getIsOpen() && <UpdateCallNoteModal refetchData={() => employerQuery.refetch()} />}
			{claimEmployerGroupModal.getIsOpen() && <ClaimEmployerGroupModal refetchData={() => employerQuery.refetch()} />}
			<div className='fade-in'>
				<PageHeader
					onBack={() => {
						navigate(saleRouter.employerSaleManagement.href());
					}}
					title='Employer Detail'
					extra={[
						<Button
							key='1'
							type='default'
							icon={<EditOutlined />}
							onClick={() => {
								updateModalMethods.handleOpenModal({});
							}}
						>
							Update
						</Button>,
						<Button
							key='2'
							type='primary'
							icon={<PlusCircleOutlined />}
							onClick={() => {
								createPointModalMethods.handleOpenModal(employerQuery.data);
							}}
							disabled={!employerQuery.data?.employer ? true : false}
						>
							Create Point Request
						</Button>,
						<Button
							key='3'
							type='primary'
							icon={<PlusCircleOutlined />}
							onClick={() =>
								claimEmployerGroupModal.handleOpenModal({ saleGroupId: saleEmployerGroupUuid, userId: userId })
							}
						>
							Claim Employer
						</Button>,
						<Button
							key='4'
							type='primary'
							icon={<SearchOutlined />}
							onClick={() => {
								navigate(saleRouter.logActivityByEmployerId.href(employerQuery.data?.employer?.uuid || ''));
							}}
							disabled={!employerQuery.data?.employer ? true : false}
						>
							View Log Activity
						</Button>,
					]}
				/>

				<div className='p-4 mx-4 space-y-4 bg-white rounded-lg'>
					<div className=''>
						<Descriptions bordered title='Lead Information'>
							<Descriptions.Item label='Lead Email'>{employerQuery.data?.lead?.email}</Descriptions.Item>
							<Descriptions.Item label='Lead Phone'>{employerQuery.data?.lead?.phone}</Descriptions.Item>
							<Descriptions.Item label=''>
								<></>
							</Descriptions.Item>
							<Descriptions.Item label='Call Note' span={3}>
								<div className='flex flex-row justify-between content-center w-full'>
									<div
										className={`w-3/4 ${showLeadCallNote ? 'see' : 'hide'}`}
										dangerouslySetInnerHTML={{
											__html: employerQuery.data
												? !showLeadCallNote
													? truncate(employerQuery.data.callNote, { length: 100 })
													: employerQuery.data.callNote
												: '',
										}}
									></div>
									<div className='w-1/4 flex justify-end'>
										<Button className='' onClick={() => setShowLeadCallNote(!showLeadCallNote)}>
											{showLeadCallNote ? <>hide note</> : <>see more</>}
										</Button>

										<Button className='mx-2'>
											<EditOutlined onClick={(e) => handleUpdateCallNote(e)} />
										</Button>
									</div>
								</div>
							</Descriptions.Item>
						</Descriptions>
					</div>

					<div className=''>
						<Descriptions bordered title='Employer Information'>
							<Descriptions.Item label='Employer Name'>
								{_get(employerQuery.data, 'employer.full_name', 'No Data')}
							</Descriptions.Item>
							<Descriptions.Item label='Employer Status'>
								<StatusTag data={_get(employerQuery.data, 'status', '')} options={colorsEmployerStatus} />
							</Descriptions.Item>
							<Descriptions.Item label='Employer Scale'>
								<StatusTag data={_get(employerQuery.data, 'scale', '')} options={colorsEmployerScale} />
							</Descriptions.Item>
							<Descriptions.Item label='Employer Type'>
								<StatusTag data={_get(employerQuery.data, 'type', '')} options={colorsEmployerType} />
							</Descriptions.Item>
							<Descriptions.Item label='Create At' span={1}>
								{moment(employerQuery.data?.createdAt).format('DD/MM/YYYY HH:mm:ss')}
							</Descriptions.Item>
							<Descriptions.Item label='Update At' span={2}>
								{moment(employerQuery.data?.updatedAt).format('DD/MM/YYYY HH:mm:ss')}
							</Descriptions.Item>
							<Descriptions.Item label='Employer Note' span={3}>
								<div className='flex flex-row justify-between content-center w-full'>
									<div
										className={`w-3/4 ${showEmpNote ? 'see' : 'hide'}`}
										dangerouslySetInnerHTML={{
											__html: employerQuery.data
												? !showEmpNote
													? truncate(employerQuery.data.other_note, { length: 100 })
													: employerQuery.data.other_note
												: '',
										}}
									></div>
									<div className='w-1/4 flex justify-end'>
										<Button className='' onClick={() => setShowEmpNote(!showEmpNote)}>
											{showEmpNote ? <>hide note</> : <>see more</>}
										</Button>
									</div>
								</div>
							</Descriptions.Item>
						</Descriptions>
					</div>

					<div>
						<Descriptions bordered title='Group Service'>
							<Descriptions.Item label='Group Name' span={2}>
								{_get(groupDetailQuery.data, 'name', 'No Data')}
							</Descriptions.Item>
							<Descriptions.Item label='Manage By'>
								{_get(groupDetailQuery.data, 'owner.email', 'No Data')}
							</Descriptions.Item>
							<Descriptions.Item label='Create At'>
								{moment(_get(groupDetailQuery.data, 'createdAt', 'No Data')).format('DD/MM/YYYY HH:mm:ss')}
							</Descriptions.Item>
						</Descriptions>
					</div>
				</div>
			</div>
		</>
	);
};

/* eslint-disable @typescript-eslint/no-unused-vars */
import { DeleteOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card, Descriptions, Modal, PageHeader, Popconfirm } from 'antd';
import { truncate } from 'lodash';
import _get from 'lodash/get';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { createSearchParams, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { saleSystemGroupApi } from '../../../core/apis';
import { IV1UpdateEmployerGroup, groupEmployerApi } from '../../../core/apis/groupEmployer';
import { InputRichEditor } from '../../../core/components/form/InputRichEditor';
import { StatusTag } from '../../../core/components/tag';
import { useModal } from '../../../core/contexts';
import { Group } from '../../../core/models';
import { colorsEmployerStatus } from '../../../core/models/employer';
import { saleAdminRouter } from '../../../core/routes';
import ClaimEmployerGroupSaleAdminModal from './components/ClaimEmployerGroup';
import { UpdateEmpGroupDrawer } from './components/UpdateEmployerDrawer';

interface SalesAdminEmployerGroupByIdProps {}

function NoteModal({ refetchData }: { refetchData: () => void }) {
	const noteModalMethods = useModal<any>('note');
	const schema = yup.object().shape({});
	const formMethods = useForm<any>({
		defaultValues: {
			note: '',
		},
		resolver: yupResolver(schema),
	});
	const addNoteMutation = useMutation(
		(data: any) => groupEmployerApi.v1NoteById(noteModalMethods.modalValue?.id, data),
		{
			onSuccess: () => {
				toast.success('success');
				refetchData();
				noteModalMethods.handleCloseModal();
			},
			onError: (error: any) => {
				toast.error(error?.response?.data?.message || 'error');
			},
		}
	);
	const editNoteMutation = useMutation(
		(data: any) =>
			groupEmployerApi.v1EditById(noteModalMethods.modalValue?.id, noteModalMethods.modalValue?.noteId, data),
		{
			onSuccess: () => {
				toast.success('success');
				refetchData();
				noteModalMethods.handleCloseModal();
			},
			onError: (error: any) => {
				toast.error(error?.response?.data?.message || 'error');
			},
		}
	);
	const employerGroupNoteByIdQuery = useQuery<any>(
		['employerGroupNoteById', noteModalMethods.modalValue?.id, noteModalMethods.modalValue?.noteId],
		async () => {
			const res = await groupEmployerApi.v1NoteInGroupById(
				noteModalMethods.modalValue?.id,
				noteModalMethods.modalValue?.noteId
			);
			formMethods.setValue('note', res.data.data.note);
			return res.data.data;
		},
		{
			enabled: noteModalMethods.modalValue?.type === 'edit',
			refetchOnWindowFocus: false,
		}
	);
	const _handleSubmit = (data: any) => {
		if (noteModalMethods.modalValue?.type === 'edit') {
			editNoteMutation.mutate(data);
		} else {
			addNoteMutation.mutate(data);
		}
	};
	return (
		<>
			<Modal
				title={noteModalMethods.modalValue?.type === 'edit' ? 'Edit Note' : 'Add Note'}
				open={noteModalMethods.getIsOpen()}
				onOk={formMethods.handleSubmit(_handleSubmit)}
				centered
				onCancel={() => {
					noteModalMethods.handleCloseModal();
				}}
				width={800}
			>
				<FormProvider {...formMethods}>
					<form className='w-full'>
						{noteModalMethods.modalValue?.type === 'edit' ? (
							employerGroupNoteByIdQuery.data && <InputRichEditor name='note' label='Note' placeholder='note..' />
						) : (
							<InputRichEditor name='note' label='Note' placeholder='note..' />
						)}
					</form>
				</FormProvider>
			</Modal>
		</>
	);
}

function UpdateCallNoteModal({ refetchData }: { refetchData: () => void }) {
	const updateCallNoteMethod = useModal<any>('callNote');
	const formMethods = useForm<any>({
		defaultValues: {
			groupUuid: undefined,
			saleUuid: undefined,
			scale: undefined,
			type: undefined,
			other_note: undefined,
			status: undefined,
			callNote: '',
		},
	});
	const employerGroupServiceIdQuery = useQuery<any>(
		['employer-group-service-id', updateCallNoteMethod.modalValue],
		async () => {
			const res = await groupEmployerApi.v1ById(updateCallNoteMethod.modalValue);
			return res.data.data;
		},
		{
			enabled: updateCallNoteMethod.modalValue != undefined,
			onSuccess: (data) => {
				const { callNote } = data;
				formMethods.setValue('callNote', callNote);
			},
		}
	);
	const updateCallNoteMutation = useMutation(
		async (data: IV1UpdateEmployerGroup) => {
			const res = await groupEmployerApi.v1UpdateById(updateCallNoteMethod.modalValue, data);

			return res;
		},
		{
			onSuccess: (data: any) => {
				refetchData();
				toast.success(data.message);
				updateCallNoteMethod.handleCloseModal();
			},
			onError: (res: any) => {
				toast.error(res?.data?.message);
			},
		}
	);
	const _handleSubmit = (data: any) => {
		updateCallNoteMutation.mutate(data);
	};
	return (
		<>
			{employerGroupServiceIdQuery.data && (
				<Modal
					title={'Edit Call Note'}
					open={updateCallNoteMethod.getIsOpen()}
					onOk={formMethods.handleSubmit(_handleSubmit)}
					centered
					onCancel={() => {
						updateCallNoteMethod.handleCloseModal();
					}}
					width={800}
				>
					<FormProvider {...formMethods}>
						<div className='w-full'>
							<InputRichEditor name='callNote' label='Call Note' placeholder='note..' className='' />
						</div>
					</FormProvider>
				</Modal>
			)}
		</>
	);
}

const SalesAdminEmployerGroupById: React.FC<SalesAdminEmployerGroupByIdProps> = () => {
	const updateModalMethods = useModal('update');
	const noteModalMethods = useModal('note');
	const updateCallNoteMethod = useModal('callNote');
	const userId = localStorage.getItem('userId') || '';
	const navigate = useNavigate();
	let [searchParams] = useSearchParams();
	const { saleEmployerGroupUuid = '' } = useParams<{ saleEmployerGroupUuid: string }>();
	const claimEmployerGroupModal = useModal<any>('claimEmployerGroup');
	const [shownDetail, setShownDetail] = useState<any>({});
	const [showLeadCallNote, setShowLeadCallNote] = useState(false);

	const handleSeeDetail = (id: any) => {
		setShownDetail((prevShownComments: any) => ({
			...prevShownComments,
			[id]: !prevShownComments[id],
		}));
	};

	const employerGroupByIdQuery = useQuery<any>(
		['employerGroupById', saleEmployerGroupUuid],
		async () => {
			const res = await groupEmployerApi.v1ById(saleEmployerGroupUuid);
			return res.data.data;
		},
		{
			enabled: Boolean(saleEmployerGroupUuid),
			refetchOnWindowFocus: false,
		}
	);
	const groupDetailQuery = useQuery<Group>(
		['group-detail', employerGroupByIdQuery.data?.group?.uuid],
		async () => {
			const { data } = await saleSystemGroupApi.v1GroupById(employerGroupByIdQuery.data?.group?.uuid || '');
			return data;
		},
		{
			enabled: Boolean(employerGroupByIdQuery.data?.group),
			refetchOnWindowFocus: false,
		}
	);
	const deleteNoteMutation = useMutation(
		(noteId: string) => groupEmployerApi.v1DeleteNote(saleEmployerGroupUuid, noteId),
		{
			onSuccess: () => {
				toast.success('delete success');
				employerGroupByIdQuery.refetch();
			},
			onError: (error: any) => {
				toast.error(error?.response?.data?.message || 'error');
			},
		}
	);

	const handleUpdateCallNote = (e) => {
		e.preventDefault();
		updateCallNoteMethod.handleOpenModal(saleEmployerGroupUuid)
	}

	useEffect(() => {
		document.title = `Employer Group | ${employerGroupByIdQuery.data?.lead?.companyName}`;
	}, []);

	return (
		<>
			<UpdateEmpGroupDrawer refetch={() => employerGroupByIdQuery.refetch()} />
			{noteModalMethods.getIsOpen() && <NoteModal refetchData={() => employerGroupByIdQuery.refetch()} />}
			{updateCallNoteMethod.getIsOpen() && <UpdateCallNoteModal refetchData={() => employerGroupByIdQuery.refetch()} />}
			{claimEmployerGroupModal.getIsOpen() && (
				<ClaimEmployerGroupSaleAdminModal refetchData={() => employerGroupByIdQuery.refetch()} />
			)}
			<div className='fade-in'>
				<PageHeader
					title={`${employerGroupByIdQuery.data?.lead?.companyName}`}
					onBack={() => {
						const params = {};

						searchParams.forEach((value, key) => {
							params[key] = value;
						});

						navigate({
							pathname: saleAdminRouter.employerSaleManagement.href(),
							search: createSearchParams(params).toString(),
						});
					}}
					extra={[
						<Button
							key={'add-note'}
							type='primary'
							icon={<PlusCircleOutlined />}
							onClick={() =>
								noteModalMethods.handleOpenModal({
									id: saleEmployerGroupUuid,
									type: 'create',
								})
							}
						>
							Add Note
						</Button>,
						// <Button
						// 	key='1'
						// 	type='primary'
						// 	icon={<PlusCircleOutlined />}
						// 	onClick={() => {
						// 		navigate(
						// 			saleAdminRouter.addSaleToEmployer.href({
						// 				employerId: employerGroupByIdQuery.data?.employer?.uuid || '',
						// 				groupId: saleEmployerGroupUuid,
						// 			})
						// 		);
						// 	}}
						// 	disabled={!employerGroupByIdQuery.data?.employer ? true : false}
						// >
						// 	Add Sale To Employer
						// </Button>,
						<Button
							key='2'
							type='primary'
							icon={<PlusCircleOutlined />}
							onClick={() =>
								claimEmployerGroupModal.handleOpenModal({ saleGroupId: saleEmployerGroupUuid, userId: userId })
							}
						>
							Claim Employer
						</Button>,
						<Button
							key='3'
							type='default'
							icon={<EditOutlined />}
							onClick={() => {
								updateModalMethods.handleOpenModal({});
							}}
						>
							Update
						</Button>,
					]}
				/>

				<div className='p-4 mx-4 space-y-4 bg-white rounded-lg'>
					<Descriptions bordered className='bg-white'>
						<Descriptions.Item label='Lead Company Name'>
							{_get(employerGroupByIdQuery.data, 'lead.companyName', '')}
						</Descriptions.Item>
						<Descriptions.Item label='Lead Email'>{employerGroupByIdQuery.data?.lead?.email || ''}</Descriptions.Item>
						<Descriptions.Item label='Lead Phone' span={3}>
							{_get(employerGroupByIdQuery.data, 'lead.phone', '')}
						</Descriptions.Item>
						<Descriptions.Item label='Call Note' span={3}>
							{employerGroupByIdQuery.data?.callNote && (
								<Card className='mt-4'>
									<div className='flex flex-row justify-between content-center w-full'>
										<div
											className={`w-3/4 ${showLeadCallNote ? 'see' : 'hide'}`}
											dangerouslySetInnerHTML={{
												__html: employerGroupByIdQuery.data
													? !showLeadCallNote
														? truncate(employerGroupByIdQuery.data.callNote, { length: 100 })
														: employerGroupByIdQuery.data.callNote
													: '',
											}}
										></div>
										<div className='w-1/4 flex justify-end'>
											<Button className='' onClick={() => setShowLeadCallNote(!showLeadCallNote)}>
												{showLeadCallNote ? <>hide note</> : <>see more</>}
											</Button>

										<Button className='mx-2'>
											<EditOutlined onClick={(e) => handleUpdateCallNote(e)} />
										</Button>
										</div>
									</div>
								</Card>
							)}
						</Descriptions.Item>
						<Descriptions.Item label='Note' span={3}>
							{employerGroupByIdQuery.data?.saleEmployerGroupNotes?.map((e, index) => {
								return (
									<Card key={index} className='mt-4'>
										<div className='flex flex-row justify-between content-center w-full'>
											<div
												className={`w-3/4 ${shownDetail[index] ? 'see' : 'hide'}`}
												dangerouslySetInnerHTML={{ __html: e.note }}
											></div>
											<div className='w-1/4 flex justify-end'>
												<Button className='mx-2' onClick={() => handleSeeDetail(index)}>
													{shownDetail[index] ? <>hide note</> : <>see more</>}
												</Button>
												<Button className='mx-2'>
													<EditOutlined
														onClick={() =>
															noteModalMethods.handleOpenModal({
																id: saleEmployerGroupUuid,
																noteId: e.uuid,
																type: 'edit',
															})
														}
													/>
												</Button>
												<Popconfirm
													title='Are you sure to delete this note?'
													onConfirm={() => {
														deleteNoteMutation.mutate(e.uuid);
													}}
													placement='bottomLeft'
												>
													<Button className='mx-2'>
														<DeleteOutlined />
													</Button>
												</Popconfirm>
											</div>
										</div>
									</Card>
								);
							})}
						</Descriptions.Item>
						<Descriptions.Item label='Source'>{_get(employerGroupByIdQuery.data, 'lead.source', '')}</Descriptions.Item>
					</Descriptions>

					<Descriptions bordered className='bg-white'>
						<Descriptions.Item label='Sale Name'>
							{employerGroupByIdQuery.data?.sale?.full_name || '-'}
						</Descriptions.Item>
						<Descriptions.Item label='Employer Full Name'>
							{employerGroupByIdQuery.data?.employer?.full_name || '-'}
						</Descriptions.Item>
						<Descriptions.Item label='Employer Email'>
							{employerGroupByIdQuery.data?.employer?.email || ''}
						</Descriptions.Item>
						<Descriptions.Item label='Employer Phone'>
							{employerGroupByIdQuery.data?.employer?.phone || ''}
						</Descriptions.Item>
						<Descriptions.Item label='Employer Status'>
							<StatusTag data={employerGroupByIdQuery.data?.status} options={colorsEmployerStatus} />
						</Descriptions.Item>
						<Descriptions.Item label='Employer Type'>
							<StatusTag data={employerGroupByIdQuery.data?.type || ''} options={colorsEmployerStatus} />
						</Descriptions.Item>
						<Descriptions.Item label='Employer Note'>
							<div dangerouslySetInnerHTML={{ __html: employerGroupByIdQuery.data?.other_note || '' }}></div>
						</Descriptions.Item>
					</Descriptions>

					<Descriptions bordered title='Group Service'>
						<Descriptions.Item label='Group Name' span={2}>
							{_get(groupDetailQuery.data, 'name', 'No Data')}
						</Descriptions.Item>
						<Descriptions.Item label='Manage By'>
							{_get(groupDetailQuery.data, 'owner.email', 'No Data')}
						</Descriptions.Item>
						<Descriptions.Item label='Create At'>
							{moment(_get(groupDetailQuery.data, 'createdAt', 'No Data')).format('DD/MM/YYYY HH:mm:ss')}
						</Descriptions.Item>
					</Descriptions>
				</div>
			</div>
		</>
	);
};

export default SalesAdminEmployerGroupById;

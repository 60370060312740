import { EditOutlined, ImportOutlined, MenuOutlined, PlusCircleOutlined, SendOutlined } from '@ant-design/icons';
import { Button, Descriptions, Dropdown, Menu, PageHeader, Popconfirm, Space } from 'antd';
import _get from 'lodash/get';
import moment from 'moment';
import * as React from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { groupMailApi } from '../../../core/apis';
import { TableBuilder } from '../../../core/components/table/TableBuilder';
import { useModal } from '../../../core/contexts';
import { GroupMail, GroupMailMember } from '../../../core/models';
import { AddGroupMailMemberDrawer } from './components/AddGroupMailMemberDrawer';
import ImportFromSheetDrawer from './components/ImportFromSheetDrawer';
import SendMailDrawer from './components/SendMailDrawer';
import { UpdateGroupMailDrawer } from './components/UpdateGroupMailDrawer';
import { UpdateGroupMailMemberDrawer } from './components/UpdateGroupMailMemberDrawer';
import { adminRouter } from '../../../core/routes';

interface ViewGroupMailByIdProps {}

const ViewGroupMailById: React.FC<ViewGroupMailByIdProps> = () => {
	const navigate = useNavigate();

	const addGroupMailMemberMethods = useModal('addGroupMailMember');
	const updateGroupMailMemberMethods = useModal<GroupMailMember>('updateGroupMailMember');
	const updateGroupMailMethods = useModal<GroupMail>('updateGroupMail');
	const importSheetMethods = useModal('importSheet');
	const sendMailMethods = useModal('sendMail');
	const params = useParams<{
		groupMailId: string;
	}>();

	const groupDetailQuery = useQuery<GroupMail>(
		[
			'groupMail',
			params.groupMailId,
			addGroupMailMemberMethods.getIsOpen(),
			updateGroupMailMemberMethods.getIsOpen(),
			updateGroupMailMethods.getIsOpen(),
			importSheetMethods.getIsOpen(),
		],
		async () => {
			const { data } = await groupMailApi.v1GetGroupMailById(params?.groupMailId || '');

			return data;
		},
		{
			refetchOnWindowFocus: false,
		}
	);

	const deleteMutation = useMutation(
		async (id: string) => {
			await groupMailApi.v1DeleteGroupMailMember(params?.groupMailId || '', id);
		},
		{
			onSuccess: () => {
				toast.success('Delete group mail member successfully');
				groupDetailQuery.refetch();
			},
			onError: () => {
				toast.error('Delete group mail member failed');
			},
		}
	);
	React.useEffect(() => {
		document.title = 'Group Mail';
	}, []);
	return (
		<>
			<SendMailDrawer />
			<ImportFromSheetDrawer />
			<UpdateGroupMailDrawer />
			<UpdateGroupMailMemberDrawer />
			<AddGroupMailMemberDrawer />
			<div className='fade-in'>
				<PageHeader
					title='Group Mail Detail'
					onBack={() => {
						navigate(adminRouter.groupMailManagement.href());
					}}
					extra={[
						<Button
							key='2'
							icon={<PlusCircleOutlined />}
							onClick={() => {
								addGroupMailMemberMethods.handleOpenModal(params.groupMailId);
							}}
						>
							Add Member
						</Button>,
						<Button
							key='1'
							type='primary'
							icon={<EditOutlined />}
							onClick={() => {
								if (groupDetailQuery.data) {
									updateGroupMailMethods.handleOpenModal(groupDetailQuery.data);
								}
							}}
						>
							Update Group Mail
						</Button>,
						<Button
							key='3'
							type='primary'
							icon={<ImportOutlined />}
							onClick={() => {
								importSheetMethods.handleOpenModal({});
							}}
						>
							Import From Sheet
						</Button>,
						<Button
							key='4'
							type='primary'
							icon={<SendOutlined />}
							onClick={() => {
								sendMailMethods.handleOpenModal({});
							}}
						>
							Send Mail
						</Button>,
					]}
				/>

				<div className='p-4 space-y-4 '>
					<Descriptions bordered className='bg-white'>
						<Descriptions.Item label='Group Name'>{_get(groupDetailQuery.data, 'name', '')}</Descriptions.Item>
						<Descriptions.Item label='Create At'>
							{moment(_get(groupDetailQuery.data, 'createdAt', '')).format('DD/MM/YYYY')}
						</Descriptions.Item>
						<Descriptions.Item label='Email'>
							{_get(groupDetailQuery.data, 'campaignMail', []).length}
						</Descriptions.Item>
						<Descriptions.Item label='Description' span={3}>
							{_get(groupDetailQuery.data, 'description', '')}
						</Descriptions.Item>
					</Descriptions>
					<TableBuilder
						columns={[
							{
								title: () => <div>Group Name</div>,
								width: 100,
								dataIndex: 'name',
								key: 'name',
								render: (text, record) => {
									const data = _get(record, 'name', 'Empty');

									return <>{data}</>;
								},
							},
							{
								title: () => <div>Email</div>,
								width: 200,
								dataIndex: 'email',
								key: 'email',
								render: (text, record) => {
									const data = _get(record, 'email', 'Empty');

									return <>{data}</>;
								},
							},

							{
								title: () => <div>Create At</div>,
								width: 100,
								dataIndex: 'createdAt',
								key: 'createdAt',
								render: (text, record) => {
									const data = _get(record, 'createdAt', 'Empty');

									return <>{moment(data).format('DD/MM/YYYY')}</>;
								},
							},

							{
								title: () => <div>Action</div>,
								width: 100,
								fixed: 'right',
								dataIndex: 'action',
								key: 'action',
								render: (text, record) => {
									const uuid = _get(record, 'uuid', '');
									return (
										<Space size='middle'>
											<Dropdown
												overlay={
													<Menu>
														<Menu.Item
															onClick={() => {
																updateGroupMailMemberMethods.handleOpenModal(record);
															}}
														>
															Update
														</Menu.Item>
														<Popconfirm
															title='Are you sure to delete this member?'
															onConfirm={() => {
																deleteMutation.mutate(uuid);
															}}
														>
															<Menu.Item danger>Delete</Menu.Item>
														</Popconfirm>
													</Menu>
												}
											>
												<a>
													<MenuOutlined />
												</a>
											</Dropdown>
										</Space>
									);
								},
							},
						]}
						data={groupDetailQuery.data?.campaignMail || []}
						isLoading={groupDetailQuery.isLoading}
						isShowPagination={false}
						rowKey='uuid'
					/>
				</div>
			</div>
		</>
	);
};

export default ViewGroupMailById;

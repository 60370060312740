import { Button, Drawer, Space } from 'antd';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { IV1EmployerPagingFilters } from '../../../../core/apis';
import { TextInput } from '../../../../core/components/form';
import { useModal, useTableUtil } from '../../../../core/contexts';
import { Comparator, User } from '../../../../core/models';
import { filterMapper } from '../../../../core/utils';

interface IV1AddWhiteListFilters extends Pick<IV1EmployerPagingFilters, 'employerPhone' | 'employerEmail'> {}

interface FilterAddWhiteListDrawerProps {}

export const FilterAddWhiteListDrawer: React.FC<FilterAddWhiteListDrawerProps> = () => {
	const filterModalMethod = useModal<User>('filter');
	const tableMethods = useTableUtil();
	const formMethods = useForm<IV1AddWhiteListFilters>({
		defaultValues: {
			employerEmail: '',
			employerPhone: '',
		},
	});

	const _handleOnSubmit = (data: IV1AddWhiteListFilters) => {
		tableMethods.setFilters(
			filterMapper<IV1AddWhiteListFilters>(
				data,
				{
					employerEmail: Comparator.LIKE,
					employerPhone: Comparator.LIKE,
				},
				{
					employerEmail: 'filter.email',
					employerPhone: 'filter.phone',
				}
			)
		);
	};

	return (
		<Drawer
			open={filterModalMethod.getIsOpen()}
			title='Filter White List Employer'
			onClose={() => filterModalMethod.handleCloseModal()}
		>
			<div>
				<FormProvider {...formMethods}>
					<form onSubmit={formMethods.handleSubmit(_handleOnSubmit)}>
						<Space direction='vertical' className='w-full'>
							<TextInput name='employerEmail' label='Employer Email' />
							<TextInput name='employerPhone' label='Employer Phone' />
						</Space>
						<Button htmlType='submit' className='mt-6'>
							Apply
						</Button>
					</form>
				</FormProvider>
			</div>
		</Drawer>
	);
};

import React from 'react';
import { CompanyPage } from '../pages/admin/company';
import { CvManagement } from '../pages/admin/cv';
import { CvApplied } from '../pages/admin/cv/CvApplied';
import { JobDetailById } from '../pages/admin/cv/CvApplied/JobById';
import { JobCommentById } from '../pages/admin/cv/CvApplied/JobCommentById';
import { PermissionCv } from '../pages/admin/cv/PermissionUser';
import ReportCv from '../pages/admin/cv/Report';
import { ViewCvManamentById } from '../pages/admin/cv/ViewDetail';
import { AddPermissionCv } from '../pages/admin/cv/components/AddPermissionUser';
import { PointCv } from '../pages/admin/cv/point';
import { DataSaleManagement } from '../pages/admin/dataSaleManagement';
import DataSaleById from '../pages/admin/dataSaleManagement/dataSaleById';
import EmployerManagement from '../pages/admin/employer';
import { AddEmployerToGroup } from '../pages/admin/employer/AddEmployerToGroup';
import EmployerById from '../pages/admin/employer/employerById';
import { FeedBack } from '../pages/admin/feedBack';
import GroupMailPage from '../pages/admin/groupMail';
import ViewGroupMailById from '../pages/admin/groupMail/ViewGroupMailById';
import { GroupSalePage } from '../pages/admin/groupSale';
import { AddMemberByGroupId } from '../pages/admin/groupSale/AddMemberByGroupId';
import { ViewGroupDetailByIdPage } from '../pages/admin/groupSale/ViewGroupDetailById';
import Homepage from '../pages/admin/home';
import MailManagementPage from '../pages/admin/mailManagement';
import { MailManagementById } from '../pages/admin/mailManagement/mailManagementById';
import { PurchaseOrderAdminMgt } from '../pages/admin/purchaseOrderAdminMgt';
import { ViewInvoice } from '../pages/admin/purchaseOrderAdminMgt/ViewInvoice';
import { SaleMemberManagement } from '../pages/admin/saleMembers';
import { ViewSaleMemberById } from '../pages/admin/saleMembers/ViewSaleMemberById';
import { SaleOrderAdminMgt } from '../pages/admin/saleOrderAdminMgt';
import { WhiteListManagement } from '../pages/admin/whiteList';
import { AddNewWhiteList } from '../pages/admin/whiteList/addWhiteList';
import { AuthLoginPage } from '../pages/auth/AuthLoginPage';
import { SaleEmployerSaleManagement } from '../pages/sale/employerSale';
import { SaleLogActivityByEmployerId } from '../pages/sale/employerSale/LogActivityByEmployerId';
import { SaleLogActivityById } from '../pages/sale/employerSale/LogActivityById';
import { SaleViewEmployerById } from '../pages/sale/employerSale/ViewEmployerById';
import { SaleGroupSalePage } from '../pages/sale/groupSale';
import { SaleViewGroupDetailByIdPage } from '../pages/sale/groupSale/ViewGroupDetailById';
import { SalePointRequestManagement } from '../pages/sale/pointRequest';
import { ViewPointRequestById } from '../pages/sale/pointRequest/ViewPointRequestById';
import SalesAdminEmployerManagement from '../pages/saleAdmin/employer';
import { AddSaleToEmployer } from '../pages/saleAdmin/employer/AddSaleToEmployer';
import SalesAdminEmployerGroupById from '../pages/saleAdmin/employer/employerById';
import { SaleAdminEmployerSaleManagementByGroupId } from '../pages/saleAdmin/employerSaleByGroupId';
import { SaleAdminViewEmployerById } from '../pages/saleAdmin/employerSaleByGroupId/ViewEmployerById';
import { SaleAdminGroupSalePage } from '../pages/saleAdmin/groupSale';
import { SaleAdminViewGroupDetailByIdPage } from '../pages/saleAdmin/groupSale/ViewGroupDetailById';
import { SaleAdminPointRequestManagement } from '../pages/saleAdmin/pointRequest';
import { SaleAdminViewPointRequestById } from '../pages/saleAdmin/pointRequest/ViewPointRequestById';
import { AuthRouterProtection } from './components/routerProtection';
import { ModalProvider, TableUtilProvider } from './contexts';

export interface IRoute {
	href: (data?: any) => string;
	Component: () => React.ReactElement;
}

export const adminRouter: Record<string, IRoute> = {
	home: {
		href: () => '/admin/home',
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<Homepage />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	dataSaleManagement: {
		href: () => '/admin/data-sale',
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<DataSaleManagement />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	dataSaleManagementId: {
		href: (id: string) => `/admin/data-sale/${id}`,
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<DataSaleById />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	saleOrderManagement: {
		href: () => '/admin/sale-order-mgt',
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<SaleOrderAdminMgt />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	purchaseOrderManagement: {
		href: () => '/admin/purchase-order-mgt',
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<PurchaseOrderAdminMgt />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	viewInvoicePurchaseDetailById: {
		href: (id: string) => `/admin/purchase-order/${id}`,
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<ViewInvoice />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	saleMemberManagement: {
		href: () => '/admin/member',
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<SaleMemberManagement />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},

	viewSaleMemberById: {
		href: (id: string) => `/member/${id}`,
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ViewSaleMemberById />
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},

	groupSaleManagement: {
		href: () => '/admin/group-sale',
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<GroupSalePage />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},

	viewGroupDetailById: {
		href: (id: string) => `/admin/group-sale/${id}`,
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<ViewGroupDetailByIdPage />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	addMemberByGroupId: {
		href: (id: string) => `/admin/group-sale/${id}/add-member`,
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<AddMemberByGroupId />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	addEmployerToGroup: {
		href: (id: string) => `/admin/employer/${id}/add-group`,
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<AddEmployerToGroup />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	employerManagement: {
		href: () => '/admin/employer',
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<EmployerManagement />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	employerById: {
		href: (id: string) => `/admin/employer/${id}`,
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<EmployerById />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	whiteListManagement: {
		href: () => '/admin/white-list',
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<WhiteListManagement />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	addNewWhiteList: {
		href: () => '/admin/white-list/add',
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<AddNewWhiteList />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	groupMailManagement: {
		href: () => '/admin/group-mail',
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<GroupMailPage />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	groupMailDetailById: {
		href: (id: string) => `/admin/group-mail/${id}`,
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<ViewGroupMailById />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	cvManagement: {
		href: () => '/admin/cv-management',
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<CvManagement />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	cvManagementById: {
		href: (id: string) => `/admin/cv-management/${id}`,
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<ViewCvManamentById />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	cvPoint: {
		href: () => '/admin/cv-management/point',
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<PointCv />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	reportCv: {
		href: () => '/admin/cv-management/report-cv',
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<ReportCv />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	permissionCv: {
		href: () => '/admin/cv-permission',
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<PermissionCv />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	AddPermissionUserCv: {
		href: () => '/admin/cv-permission/add',
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<AddPermissionCv />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	CvApplied: {
		href: () => '/admin/cv-applied',
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<CvApplied />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	jobDetailById: {
		href: (id: string) => `/admin/cv-applied/${id}`,
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<JobDetailById />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	jobCommentId: {
		href: (id: string) => `/admin/job-comment/${id}`,
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<JobCommentById />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	companyPage: {
		href: () => '/admin/company',
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<CompanyPage />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	feedBackPage: {
		href: () => '/admin/feed-back',
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<FeedBack />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	sendMailManagement: {
		href: () => '/admin/send-mail-management',
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<MailManagementPage />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	sendMailManagementById: {
		href: (id: string) => `/admin/send-mail-management/${id}`,
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<MailManagementById />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
};

export const saleAdminRouter: Record<string, IRoute> = {
	home: {
		href: () => '/sale-admin/home',
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<Homepage />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	dataSaleManagement: {
		href: () => '/sale-admin/data-sale',
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<DataSaleManagement />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	dataSaleManagementId: {
		href: (id: string) => `/sale-admin/data-sale/${id}`,
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<DataSaleById />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	saleOrderManagement: {
		href: () => '/sale-admin/sale-order-mgt',
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<SaleOrderAdminMgt />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	purchaseOrderManagement: {
		href: () => '/sale-admin/purchase-order-mgt',
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<PurchaseOrderAdminMgt />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	viewInvoicePurchaseDetailById: {
		href: (id: string) => `/sale-admin/purchase-order/${id}`,
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<ViewInvoice />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	employerSaleManagement: {
		href: () => '/sale-admin/employer',
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<SalesAdminEmployerManagement />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	employerById: {
		href: (saleEmployerGroupUuid: string) => `/sale-admin/employer/${saleEmployerGroupUuid}`,
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<SalesAdminEmployerGroupById />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	groupSaleManagement: {
		href: () => '/sale-admin/group',
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<SaleAdminGroupSalePage />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	viewGroupDetailById: {
		href: (id: string) => `/sale-admin/group-sale/${id}`,
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<SaleAdminViewGroupDetailByIdPage />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	viewEmployerByGroupId: {
		href: (id: string) => `/sale-admin/group-sale/${id}/employer`,
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<SaleAdminEmployerSaleManagementByGroupId />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	viewEmployerDetailById: {
		href: ({ employerId, groupId }: { employerId: string; groupId: string }) =>
			`/sale-admin/group-sale/${groupId}/employer/${employerId}`,
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<SaleAdminViewEmployerById />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	addSaleToEmployer: {
		href: ({ employerId, groupId }: { employerId: string; groupId: string }) =>
			`/sale-admin/group-sale/${groupId}/employer/${employerId}/add-sale`,
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<AddSaleToEmployer />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	pointRequestByGroupIdManagement: {
		href: (groupId: string) => `/sale-admin/group-sale/${groupId}/point-request`,
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<SaleAdminPointRequestManagement />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},

	viewPointRequestDetailById: {
		href: ({ groupId, pointRequestId }: { groupId: string; pointRequestId: string }) =>
			`/sale-admin/group-sale/${groupId}/point-request/${pointRequestId}`,
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<SaleAdminViewPointRequestById />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	cvManagement: {
		href: () => '/sale-admin/cv-management',
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<CvManagement />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	cvManagementById: {
		href: (id: string) => `/sale-admin/cv-management/${id}`,
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<ViewCvManamentById />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	reportCv: {
		href: () => '/sale-admin/cv-management/report-cv',
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<ReportCv />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	feedBackPage: {
		href: () => '/sale-admin/feed-back',
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<FeedBack />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	CvApplied: {
		href: () => '/sale-admin/cv-applied',
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<CvApplied />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	jobDetailById: {
		href: (id: string) => `/sale-admin/cv-applied/${id}`,
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<JobDetailById />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	jobCommentId: {
		href: (id: string) => `/sale-admin/job-comment/${id}`,
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<JobCommentById />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	companyPage: {
		href: () => '/sale-admin/company',
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<CompanyPage />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	sendMailManagement: {
		href: () => '/sale-admin/send-mail-management',
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<MailManagementPage />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	sendMailManagementById: {
		href: (id: string) => `/sale-admin/send-mail-management/${id}`,
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<MailManagementById />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
};

export const saleRouter: Record<string, IRoute> = {
	home: {
		href: () => '/sale/home',
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<Homepage />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	dataSaleManagement: {
		href: () => '/sale/data-sale',
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<DataSaleManagement />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},

	dataSaleManagementId: {
		href: (id: string) => `/sale/data-sale/${id}`,
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<DataSaleById />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	groupSaleManagement: {
		href: () => '/sale/group',
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<SaleGroupSalePage />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	viewGroupDetailById: {
		href: (id: string) => `/sale/group-sale/${id}`,
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<SaleViewGroupDetailByIdPage />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	pointRequestManagement: {
		href: () => '/sale/point-request',
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<SalePointRequestManagement />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	saleOrderManagement: {
		href: () => '/sale/sale-order-mgt',
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<SaleOrderAdminMgt />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	purchaseOrderManagement: {
		href: () => '/sale/purchase-order-mgt',
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<PurchaseOrderAdminMgt />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	viewInvoicePurchaseDetailById: {
		href: (id: string) => `/sale/purchase-order/${id}`,
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<ViewInvoice />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	feedBackPage: {
		href: () => '/sale/feed-back',
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<FeedBack />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	viewPointRequestDetailById: {
		href: (id: string) => `/sale/point-request/${id}`,
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<ViewPointRequestById />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	employerSaleManagement: {
		href: () => '/sale/employer',
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<SaleEmployerSaleManagement />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	viewEmployerDetailById: {
		href: (saleEmployerGroupUuid: string) => `/sale/employer/${saleEmployerGroupUuid}`,
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<SaleViewEmployerById />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	logActivityByEmployerId: {
		href: (id: string) => `/sale/employer/${id}/log-activity`,
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<SaleLogActivityByEmployerId />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	logActivityById: {
		href: ({ id, employerId }: { id: string; employerId: string }) => `/sale/employer/${employerId}/log-activity/${id}`,
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<SaleLogActivityById />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	cvManagement: {
		href: () => '/sale/cv-management',
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<CvManagement />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	cvManagementById: {
		href: (id: string) => `/sale/cv-management/${id}`,
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<ViewCvManamentById />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	reportCv: {
		href: () => '/sale/cv-management/report-cv',
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<ReportCv />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	CvApplied: {
		href: () => '/sale/cv-applied',
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<CvApplied />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	jobDetailById: {
		href: (id: string) => `/sale/cv-applied/${id}`,
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<JobDetailById />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	jobCommentId: {
		href: (id: string) => `/sale/job-comment/${id}`,
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<JobCommentById />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	companyPage: {
		href: () => '/sale/company',
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<CompanyPage />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	sendMailManagement: {
		href: () => '/sale/send-mail-management',
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<MailManagementPage />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
	sendMailManagementById: {
		href: (id: string) => `/sale/send-mail-management/${id}`,
		Component: () => (
			<AuthRouterProtection>
				<TableUtilProvider>
					<ModalProvider>
						<MailManagementById />
					</ModalProvider>
				</TableUtilProvider>
			</AuthRouterProtection>
		),
	},
};

export const routes: Record<string, IRoute> = {
	authLogin: {
		href: () => '/',
		Component: () => (
			// <UnAuthRouterProtection>
			<AuthLoginPage />
			// </UnAuthRouterProtection>
		),
	},
};

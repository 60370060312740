/* eslint-disable @typescript-eslint/no-unused-vars */
import { FilterOutlined, MenuOutlined, PlusCircleOutlined, SearchOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, PageHeader, Space, Tag } from 'antd';
import _, { truncate } from 'lodash';
import _get from 'lodash/get';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { Link, createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { groupEmployerApi } from '../../../core/apis/groupEmployer';
import { TextInput } from '../../../core/components/form';
import { TableBuilder } from '../../../core/components/table/TableBuilder';
import { useModal, useTableUtil } from '../../../core/contexts';
import { IResponseList } from '../../../core/models';
import { adminRouter } from '../../../core/routes';
import { FilterEmpGroupDrawer } from './components/FilterSaleEmployerDrawer';
import AddNewLeadEmployerModal from './components/AddNewLeadEmployer';

interface EmployerManagementProps {}

const EmployerManagement: React.FC<EmployerManagementProps> = () => {
	const uploadFileRef: any = useRef(null);
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const { limit, page, setTotalItems, filters, setFilters, setPage } = useTableUtil();
	const filterModalMethods = useModal('filter');
	const addNewLeadEmployerModal = useModal<any>('addNewLeadEmployer');
	const [loadingImport, setLoadingImport] = useState(false);

	const employerGroupQuery = useQuery<IResponseList<any>>(
		[
			'employerPaging',

			{
				limit,
				page,
				filters,
			},
		],
		async () => {
			const queryParams = { ...filters, ...searchParams, page, limit };
			setSearchParams({ ...queryParams });

			const { data } = await groupEmployerApi.v1Paging({
				page,
				limit,
				...filters,
			});
			const totalItems: number = data.data.meta.totalItems || 0;
			setTotalItems(totalItems);
			return data.data;
		},
		{
			refetchOnWindowFocus: false,
		}
	);
	const formMethods = useForm<any>({
		defaultValues: {
			'filter.lead.companyName': '',
			'filter.lead.email': '',
			'filter.lead.phone': '',
		},
	});
	const handleUploadFile = async (e: any) => {
		const file = e.target.files[0];
		const formData = new FormData();
		formData.append('file', file);

		if (typeof file === 'undefined') return;

		const fileType = [
			'application/msword',
			'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		];
		if (fileType?.includes(file?.type)) {
			setLoadingImport(true);
			try {
				const { data } = await groupEmployerApi.v1Import(formData);
				employerGroupQuery.refetch();
				toast.success(data?.message || '');
				setLoadingImport(false);
			} catch (error: any) {
				setLoadingImport(false);
				toast.error(error?.response?.data?.message || 'error');
			}
		}
	};
	useEffect(() => {
		const params = {};

		searchParams.forEach((value, key) => {
			params[key] = value;
		});

		const filterCompanyName = params['filter.lead.companyName']?.replace('$ilike:', '') || '';
		const filterEmail = params['filter.lead.email']?.replace('$ilike:', '') || '';
		const filterPhone = params['filter.lead.phone']?.replace('$ilike:', '') || '';

		formMethods.setValue('filter.lead.companyName', filterCompanyName);
		formMethods.setValue('filter.lead.email', filterEmail);
		formMethods.setValue('filter.lead.phone', filterPhone);

		setFilters(params);
	}, []);

	useEffect(() => {
		const _filters = {
			...filters,
			'filter.lead.companyName': `$ilike:${formMethods.watch('filter.lead.companyName')}` || '',
			'filter.lead.email': `$ilike:${formMethods.watch('filter.lead.email')}` || '',
			'filter.lead.phone': `$ilike:${formMethods.watch('filter.lead.phone')}` || '',
		};
		if (_.isEqual(_filters, filters)) return;

		setFilters(_filters);
		setPage('1');
	}, [
		formMethods.watch('filter.lead.companyName'),
		formMethods.watch('filter.lead.email'),
		formMethods.watch('filter.lead.phone'),
	]);
	useEffect(() => {
		document.title = 'Employer Group';
	}, []);
	return (
		<>
			<FilterEmpGroupDrawer />
			{addNewLeadEmployerModal.getIsOpen() && (
				<AddNewLeadEmployerModal refetchData={() => employerGroupQuery.refetch()} />
			)}
			<div className='fade-in mb-5'>
				<PageHeader
					title='Employer Management'
					extra={[
						<Button
							key={'upload'}
							icon={<UploadOutlined />}
							onClick={() => uploadFileRef.current.click()}
							loading={loadingImport}
						>
							Import
							<input
								id='chooseFileLocal'
								type='file'
								onChange={handleUploadFile}
								ref={uploadFileRef}
								accept='.xlsx, .xls, .csv'
								className='opacity-0 invisible fixed'
							/>
						</Button>,
						<Button
							key='1'
							icon={<PlusCircleOutlined />}
							type='primary'
							onClick={() => {
								addNewLeadEmployerModal.handleOpenModal({});
							}}
						>
							Add new lead employer
						</Button>,
						<Button
							key='2'
							icon={<FilterOutlined />}
							type='primary'
							onClick={() => {
								filterModalMethods.handleOpenModal({});
							}}
						>
							Filter
						</Button>,
					]}
				/>
				<div className='p-4 space-y-4'>
					<TableBuilder
						columns={[
							{
								title: () => <div>No.</div>,
								width: 50,
								dataIndex: 'no',
								key: 'no',
								render: (text, record, index) => {
									return <>{index + 1}</>;
								},
							},
							// {
							// 	title: () => <div>Lead Email</div>,
							// 	width: 100,
							// 	dataIndex: 'lead.email',
							// 	key: 'lead.email',
							// 	render: (text, record) => {
							// 		const data = _get(record, 'lead.email', '-');

							// 		return <>{data}</>;
							// 	},
							// 	filterIcon: <SearchOutlined />,
							// 	filterDropdown: (
							// 		<div className='p-2'>
							// 			<FormProvider {...formMethods}>
							// 				<form className='w-full flex items-end justify-start'>
							// 					<TextInput name='filter.lead.email' label='Email' placeholder='email..' className='w-52' />
							// 				</form>
							// 			</FormProvider>
							// 		</div>
							// 	),
							// },
							{
								title: () => <div>Employer Email</div>,
								width: 100,
								dataIndex: 'employer.email',
								key: 'employer.email',
								render: (text, record) => {
									const data = _get(record, 'lead.email', '-');
									const dataEmailEmployer = _get(record, 'employer.email', '-');

									return <>{record.employer?.email ? dataEmailEmployer : data}</>;
								},
							},
							{
								title: () => <div>Lead Status</div>,
								width: 50,
								dataIndex: 'isActive',
								key: 'isActive',
								align: 'center',
								render: (text, record) => {
									const data = _get(record, 'isActive', '');
									return <>{data ? <Tag color={'green'}>isActive</Tag> : <Tag color={'red'}>Not Active</Tag>}</>;
								},
							},
							{
								title: () => <div>Lead Company Name</div>,
								width: 150,
								dataIndex: 'lead.companyName',
								key: 'lead.companyName',
								render: (text, record) => {
									const data = _get(record, 'lead.companyName', '-');
									const dataEmpCompanyName = _get(record, 'employer.company.name', '-');

									return <>{record.employer?.company ? dataEmpCompanyName : data}</>;
								},
								filterIcon: <SearchOutlined />,
								filterDropdown: (
									<div className='p-2'>
										<FormProvider {...formMethods}>
											<form className='w-full flex items-end justify-start'>
												<TextInput
													name='filter.lead.companyName'
													label='Company'
													placeholder='company..'
													className='w-52'
												/>
											</form>
										</FormProvider>
									</div>
								),
							},
							{
								title: () => <div>Lead Phone</div>,
								width: 100,
								dataIndex: 'lead.phone',
								key: 'lead.phone',
								render: (text, record) => {
									const data = _get(record, 'lead.phone', '-');
									const dataEmployerPhone = _get(record, 'employer.phone', '-');

									return <>{record.employer?.phone ? dataEmployerPhone : data}</>;
								},
								filterIcon: <SearchOutlined />,
								filterDropdown: (
									<div className='p-2'>
										<FormProvider {...formMethods}>
											<form className='w-full flex items-end justify-start'>
												<TextInput name='filter.lead.phone' label='Phone' placeholder='phone..' className='w-52' />
											</form>
										</FormProvider>
									</div>
								),
							},
							{
								title: () => <div>Lead Call Noe</div>,
								width: 200,
								dataIndex: 'callNote',
								key: 'callNote',
								render: (text, record) => {
									const data = _get(record, 'callNote', '');

									return (
										<div
											dangerouslySetInnerHTML={{
												__html: truncate(data, {
													length: 55,
												}),
											}}
										></div>
									);
								},
							},
							{
								title: () => <div>Group Service</div>,
								width: 100,
								dataIndex: 'group.name',
								key: 'group.name',
								render: (text, record) => {
									if (record.group) {
										const data = _get(record, 'group.name', '-');
										return <Link to={adminRouter.viewGroupDetailById.href(record.group.uuid)}>{data}</Link>;
									}

									const data = _get(record, 'group.name', '-');

									return <>{data}</>;
								},
							},
							{
								title: () => <div>Create At</div>,
								width: 100,
								dataIndex: 'createdAt',
								key: 'createdAt',
								render: (text, record) => {
									const data = _get(record, 'createdAt', 'Empty');

									return <>{moment(data).format('DD/MM/YYYY')}</>;
								},
							},
							{
								title: () => <div>Action</div>,
								width: 50,
								dataIndex: 'action',
								key: 'action',
								align: 'center',
								render: (text, record) => {
									return (
										<Space size='middle'>
											<Dropdown
												overlay={
													<Menu>
														<Menu.Item
															onClick={() => {
																const params = {};

																searchParams.forEach((value, key) => {
																	params[key] = value;
																});

																navigate({
																	pathname: adminRouter.employerById.href(record.uuid),
																	search: createSearchParams({ ...params }).toString(),
																});
															}}
														>
															View Detail
														</Menu.Item>
													</Menu>
												}
											>
												<a>
													<MenuOutlined />
												</a>
											</Dropdown>
										</Space>
									);
								},
							},
						]}
						data={employerGroupQuery.data?.data || []}
						isLoading={employerGroupQuery.isLoading}
						rowKey='uuid'
						size='large'
					/>
				</div>
			</div>
		</>
	);
};

export default EmployerManagement;
